
import {defineComponent} from "vue";


export default defineComponent({
  name: "DetailFactoryRoleModal",
  props: {
    modalId: {type: String, default: ""},
    title: {type: String, default: ""},
    titleIsco: {type: String, default: ""},
    numIsco: {type: Number, default: 0},
    nameNace: {type: String, default: ""},
    nameKkov: {type: String, default: ""},
    wage: {type: Number, default: 0},
    wageType: {type: String, default: ""},
    empType: {type: String, default: ""},
    shift: {type: String, default: ""},


  },

});
