
import {defineComponent} from "vue";
import AddFactoryRoleForm from "@/components/forms/addFactoryRole.vue"

export default defineComponent({
  name: "AddFactoryModal",
  components: {
    AddFactoryRoleForm
  },
});
