
import {defineComponent} from "vue";
import FactoryRoleOverviewTable from "@/components/widgets/tables/FactoryRoleOverviewTable.vue";


export default defineComponent({
  name: "RoleOverview",
  components: {
    FactoryRoleOverviewTable,
  },
});
