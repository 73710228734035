
import {defineComponent, ref} from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import {IRole} from "@/model/role";

import ApiService from "@/core/services/ApiService";
import {Api} from "@/store/enums/StoreEnums";
import AddRoleModal from "@/views/factory/modals/AddRoleModal.vue";
import DetailRoleModal from "@/views/factory/modals/DetailRoleModal.vue";

export default defineComponent({
  name: "FactoryRoleOverviewTable",
  components: {
    Datatable,
    AddRoleModal,
    DetailRoleModal
  },

  setup() {

    const tableHeader = ref([
      {
        name: "Název pozice",
        key: "title",
        sortable: true,
      },
      {
        name: "ISCO",
        key: "name_isco",
        sortable: true,
      },
      {
        name: "KKOV",
        key: "name_kkov",
        sortable: true,
      },
      {
        name: "Mzda",
        key: "wage",
        sortable: true,
      },
      {
        name: "Nace",
        key: "name_nace",
        sortable: true,
      },
      {
        name: "Akce",
        key: "actions",
        sortable: false,
      },
    ]);

    const search = ref<string>("");

    return {
      tableHeader,
      search
    };
  },
  data() {
    return {
      roles: [],
      rolesCount: 0,
      checkedRoles: [],
      factory_id: this.$route.params.idFactory
    }
  },
  created() {
    if (this.factory_id != "0") {
      this.factory_id = this.$route.params.idFactory
      ApiService.get(Api.GET_ROLE_IN_FACTORY, {factory_id: this.$route.params.idFactory}).then(({data}) => {
        this.roles = data;
        (this.$refs['datatable_component'] as any).setData(data);
        this.rolesCount = data.length;
      }).catch(({response}) => {
        console.error(response.data.errors);
      });
    }
  },
  methods: {
    deleteEmployee(id) {
      let factory: Array<IRole> = this.roles;
      for (let i = 0; i < factory.length; i++) {
        if (factory[i].id === id) {
          factory.splice(i, 1);
        }
      }
    },
    deleteSelectedEmployers() {
      let checkedRoles: Array<IRole> = this.checkedRoles;
      checkedRoles.forEach((item) => {
        this.deleteEmployee(item);
      });
      checkedRoles.length = 0;
    },
    searchItems() {
      let factory: Array<IRole> = this.roles;

      let initFactory: Array<IRole> = [];
      console.log(this.search);
      factory.splice(0, factory.length, ...initFactory);
      if (this.search !== "") {
        let results: Array<IRole> = [];
        for (let j = 0; j < factory.length; j++) {
          if (this.searchingFunc(factory[j], this.search)) {
            results.push(factory[j]);
          }
        }
        factory.splice(0, factory.length, ...results);
      }
    },
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    }
  },
});
